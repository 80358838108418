import { Address4 } from './ipv4';
import { Address6 } from './ipv6';
import { AddressError } from './address-error';

export { Address4 };
export { Address6 };
export { AddressError };

import * as helpers from './v6/helpers';

export const v6 = { helpers };
